import { FMSApiComm } from 'comm';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto } from 'dto/IMASResponseDto';
import type { UserAccessTokenDto } from 'dto/UserDtos';
import type {
  GetAgreementTermsResponse,
  PatchAgreementTermsParams,
  PostVerifiedCodeConfirmParams,
  PostVerifiedCodeConfirmResponse,
  PostVerifiedCodeParams,
  PostVerifiedCodeResponse,
  RequestBySmsOrEmailParams,
  VerifyCodeParams,
} from 'types/api/login';

const BIZ_CAR = 'bizcar';

export function login(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/login/bizcar',
    params,
  ).then((resp) => resp.data);
}

export function findId(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/find-username?' + encodeQueryData(params),
  ).then((resp) => resp.data);
}

// 마스킹 비밀번호 정보 받아오기 (이메일 유효성 검사)
export async function validEmail(
  email: string,
): Promise<IMASResponseDto<string>> {
  const response = await FMSApiComm.getData<IMASResponseDto<string>>(
    `/fms/v1/user/find-masked-phone?email=${email}`,
  );

  return response.data;
}

/** @deprecated 비밀번호 찾기 인증번호 이메일 보내기 */
export function sendVerifyCode(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/find-password/request/bizcar',
    params,
  ).then((resp) => resp.data);
}

// 비밀번호 찾기 인증번호 문자 or 이메일 발송
export async function requestVerifyCodeBySmsOrEmail(
  params: RequestBySmsOrEmailParams,
) {
  const response = await FMSApiComm.postData(
    '/fms/v1/user/find-password/sms-email-request',
    params,
  );

  return response.data;
}

// 비밀번호 인증코드 확인
export function enterVerifyCode(
  params: VerifyCodeParams,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/find-password/verification',
    params,
  ).then((resp) => resp.data);
}

// 비밀번호 변경
export function changePassword(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/find-password',
    params,
  ).then((resp) => resp.data);
}

// 약관 문서
export function getTerms(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/legal' + encodeQueryData(params),
  ).then((resp) => resp.data);
}

// 인증 코드 SMS 발급 요청 (연락처 변경시, 비밀번호 변경시)
export function postVerifiedCode({ body }: PostVerifiedCodeParams) {
  return FMSApiComm.postData<IMASResponseDto<PostVerifiedCodeResponse>>(
    '/fms/v1/users/bizcar/sms-certification/send',
    body,
  ).then((response) => response.data);
}

// 인증 코드 SMS 확인 (연락처 변경시, 비밀번호 변경시, 최초 로그인 본인 인증시)
export function postVerifiedCodeConfirm({
  body,
}: PostVerifiedCodeConfirmParams) {
  return FMSApiComm.postData<IMASResponseDto<PostVerifiedCodeConfirmResponse>>(
    '/fms/v1/users/bizcar/sms-certification/confirm',
    body,
  ).then((response) => response.data);
}

// 약관 문서 (최초 로그인시)
export function getAgreementTerms() {
  return FMSApiComm.getData<IMASResponseDto<GetAgreementTermsResponse>>(
    '/fms/v1/user/legal',
  ).then((response) => response.data);
}

export function patchAgreementTerms({ userId }: PatchAgreementTermsParams) {
  return FMSApiComm.patchData<IMASResponseDto<null>>(
    `/fms/v1/users/manager/${BIZ_CAR}/${userId}/agreement-confirm`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}
