import { FMSApiComm } from 'comm';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto } from 'dto/IMASResponseDto';
import type { UserAccessTokenDto } from 'dto/UserDtos';

export function carList(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/by-customer?' + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function dayTripList(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/' + params.carId + '/trip?' + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function favoritePlaces(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/' +
      params.carId +
      '/favorite-places?' +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function tripDetail(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/' +
      params.carId +
      '/trip/' +
      params.tripId +
      '?' +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function monthlyDayTrip(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/' +
      params.carId +
      '/trip/monthly' +
      '?' +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
