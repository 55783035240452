import { FMSApiComm, FMSReservationApiComm, FMSStaffApiComm } from 'comm';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto, IMASResponseType } from 'dto/IMASResponseDto';
import type { UserAccessTokenDto } from 'dto/UserDtos';
import type {
  GarageCreatedType,
  GarageType,
  GetAvailableCarListResponse,
  ReturnDetailType,
} from 'types/api/reservation';

// 과태료 등록하기
export function postFee(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/fine/manager/bizcar`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 예약목록 조회
export function getReservationList(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/reservation/manager/bizcar?` + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 예약 가능한 차량 리스트 조회
export function getAvailableList(params?: any) {
  return FMSApiComm.getData<IMASResponseDto<GetAvailableCarListResponse[]>>(
    `/fms/v1/reservation/bizcar/cars?` + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 차량 직접 예약
export function postReservation(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/reservation/manager/bizcar`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 차량 직접 예약 변경(운행 시작 전)
export function updateReservation(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.putData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/reservation/manager/bizcar/${params.reservationId}`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 차량 예약 연장(운행 시작 후)
export function extendReservation(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.patchData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/reservation/manager/bizcar/${params.reservationId}`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 차량 반납
export function returnReservation(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/reservation/manager/bizcar/${params.reservationId}/return`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 차량 예약 취소
export function deleteReservation(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.deleteData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/reservation/manager/bizcar/${params.reservationId}`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function getReservationListByCar(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/manager/bizcar/customer-cars-daily-reservation?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export async function getReturnDetail(reservationId: number) {
  return FMSReservationApiComm.getData<IMASResponseDto<ReturnDetailType>>(
    `fms/v1/reservation/rental/manager/bizcar?reservationId=${reservationId}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export async function getGarageList(params: {
  branchId: number;
  companyId: number;
  page: number;
  size: number;
  sort?: string[];
}) {
  return FMSStaffApiComm.getData<IMASResponseType<GarageType[]>>(
    `admin/fms/v1/branch/garage?${encodeQueryData(params)}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export async function postGarage({
  branchId,
  params,
}: {
  branchId: number;
  params: Pick<GarageType, 'name' | 'location' | 'address' | 'memo'>;
}) {
  return FMSStaffApiComm.postData<IMASResponseDto<GarageCreatedType>>(
    `admin/fms/v1/branch/${branchId}/garage`,
    [params],
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export async function deleteGarage(garageId: number) {
  return FMSStaffApiComm.deleteData<IMASResponseType<boolean, null>>(
    `admin/fms/v1/branch/garage/${garageId}?${encodeQueryData({
      isDelete: true,
    })}`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export async function patchReturnDetailAddress({
  rentalId,
  returnDetailAddress,
}: {
  rentalId: number;
  returnDetailAddress: string;
}) {
  return FMSReservationApiComm.patchData<IMASResponseDto<null>>(
    `fms/v1/reservation/rental/manager/bizcar/return-detail-address`,
    {
      rentalId,
      returnDetailAddress,
    },
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
