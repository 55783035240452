import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
    @import url('https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css');
    :root {
        --color-primary: #00c3bc;
        --color-primary-hover: #009C96;
        --color-neutral: #f7f7f7;
        --black-222: #222;
        --gray-666: #666;

    }
    html, body, #root, .App {
        margin: 0px;
        padding: 0px;
        width: 100%;
        height: 100%;
        color: var(--black-222);
    }
    * {
        font-family: 'Pretendard';
    }
    button {
        cursor: pointer;
        border: none;
    }

    // Log 
    .ModalDatePicker {
        z-index: 999999;
    }

    /* 과태료 등록하기 캘린더 커스텀 클래스네임: calendar-popup */
    .calendar-popup .ant-picker-footer {
        display: none!important;
    }

    .calendar-popup .ant-picker-cell-selected>div {
        /* width: 40px; */
        /* height: 40px!important; */
        box-shadow: 0 4px 8px 0 rgba(0, 195, 188, 0.2);
        border-radius: 100%!important;
    }
    .calendar-popup .ant-picker-cell-inner:hover {
        border-radius: 100%!important;

    }

    .calendar-popup .ant-picker-cell-today .ant-picker-cell-inner::before{
        border-radius: 100%!important;
    }

    .calendar-popup .ant-picker-header {
        border-bottom: none;
    }
    
    :not(:has(.reservationCheck)) {
        .ant-table-header {
            table > thead > tr > th {
                padding: 16px 0px !important;
            }
        }
    }
`;

export default GlobalStyle;
