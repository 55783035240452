import { FMSApiComm, FMSStaffApiComm } from 'comm';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto, IMASResponseType } from 'dto/IMASResponseDto';
import type {
  DeleteDepartmentParams,
  DeleteDepartmentResponse,
  DeleteEmailDomainParams,
  DeleteUserMemoParams,
  DeleteUserMemoResponse,
  GetDepartmentListParams,
  GetDepartmentListResponseWithPage,
  GetDepartmentNameListResponse,
  GetEmailDomainParams,
  GetEmailDomainResponse,
  GetMemberListParams,
  GetMemberListResponseWithPage,
  GetMemberParams,
  GetMemberResponse,
  GetUserMemoListParams,
  GetUserMemoListReponseWithPage,
  PatchDepartmentParams,
  PatchDepartmentResponse,
  PatchMemberParams,
  PatchMemberResponse,
  PatchUserDepartmentParams,
  PatchUserDepartmentReponse,
  PatchUserMemoParams,
  PathcUserMemoReponse,
  PostDepartmentParams,
  PostDepartmentResponse,
  PostEmailDomainParams,
  PostMemberExcelParams,
  PostMemberExcelResponse,
  PostMemberParams,
  PostMemberResponse,
  PostUserMemoParams,
  PostUserMemoResponse,
} from 'types/api/member';

const BIZ_DOMAIN = 'bizcar';

// 회원 목록 조회
export function getMemberList({
  searchText = '',
  deptId = '',
  roleName = '',
  pagination,
}: GetMemberListParams) {
  return FMSApiComm.getData<IMASResponseType<GetMemberListResponseWithPage>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}?${encodeQueryData({
      searchText,
      ...pagination,
      deptId,
      roleNames:
        roleName === ''
          ? ['CAR_USER', 'COMPANY_MANAGER', 'BRANCH_MANAGER']
          : roleName === 'CAR_USER'
            ? [roleName]
            : ['COMPANY_MANAGER', 'BRANCH_MANAGER'],
    })}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 부서 목록 조회
export function getDepartmentList({
  name,
  pagination,
}: GetDepartmentListParams) {
  return FMSApiComm.getData<IMASResponseDto<GetDepartmentListResponseWithPage>>(
    `/fms/v1/users/dept/manager/${BIZ_DOMAIN}?${encodeQueryData({
      name,
      ...pagination,
    })}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 부서 목록 조회
export async function getDepartmentNameList({
  companyId,
}: {
  companyId: number;
}) {
  return FMSStaffApiComm.getData<
    IMASResponseType<GetDepartmentNameListResponse[], null>
  >(
    `admin/fms/v1/depts?${encodeQueryData({ companyId })}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 부서 등록하기
export function postDepartment({ body }: PostDepartmentParams) {
  return FMSApiComm.postData<IMASResponseType<PostDepartmentResponse>>(
    `/fms/v1/users/dept/manager/${BIZ_DOMAIN}`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 부서 수정하기
export function updateDepartment({ deptId, body }: PatchDepartmentParams) {
  return FMSApiComm.patchData<IMASResponseDto<PatchDepartmentResponse>>(
    `/fms/v1/users/dept/manager/${BIZ_DOMAIN}/${deptId}`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 부서 삭제
export function deleteDepartment({ deptId }: DeleteDepartmentParams) {
  return FMSApiComm.deleteData<IMASResponseDto<DeleteDepartmentResponse>>(
    `/fms/v1/users/dept/manager/${BIZ_DOMAIN}/${deptId}`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회원 조회
export function getMember({ userId }: GetMemberParams) {
  return FMSApiComm.getData<IMASResponseDto<GetMemberResponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/${userId}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회원 등록
export function postMember({ body }: PostMemberParams) {
  return FMSApiComm.postData<IMASResponseDto<PostMemberResponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 다중 회원 등록 (엑셀 업로드)
export function postMemberExcel({ body }: PostMemberExcelParams) {
  return FMSApiComm.postFormData<IMASResponseDto<PostMemberExcelResponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/excel-upload`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회원 수정
export function patchMember({ userId, body }: PatchMemberParams) {
  return FMSApiComm.patchData<IMASResponseDto<PatchMemberResponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/${userId}`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회원 부서 수정
export function patchUserDepartment({
  userId,
  body,
}: PatchUserDepartmentParams) {
  return FMSApiComm.patchData<IMASResponseDto<PatchUserDepartmentReponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/${userId}`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회원 메모 조회
export function getUserMemoList({ userId }: GetUserMemoListParams) {
  return FMSApiComm.getData<IMASResponseDto<GetUserMemoListReponseWithPage>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/user/${userId}/memo`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회원 메모 등록
export function postUserMemo({ userId, body }: PostUserMemoParams) {
  return FMSApiComm.postData<IMASResponseDto<PostUserMemoResponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/user/${userId}/memo`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회원 메모 수정
export function PatchUserMemo({ userId, memoId, body }: PatchUserMemoParams) {
  return FMSApiComm.patchData<IMASResponseDto<PathcUserMemoReponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/user/${userId}/memo/${memoId}`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회원 메모 삭제
export function deleteUserMemo({ userId, memoId }: DeleteUserMemoParams) {
  return FMSApiComm.deleteData<IMASResponseDto<DeleteUserMemoResponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/user/${userId}/memo/${memoId}`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 이메일 도메인 조회
export function getEmailDomains({ companyId }: GetEmailDomainParams) {
  return FMSApiComm.getData<IMASResponseDto<GetEmailDomainResponse>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/email-domains?${encodeQueryData({
      companyId,
    })}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 이메일 도메인 추가
export function postEmailDomain({ body }: PostEmailDomainParams) {
  return FMSApiComm.postData<IMASResponseDto<boolean>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/email-domains`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 이메일 도메인 삭제
export function deleteEmailDomain({ id }: DeleteEmailDomainParams) {
  return FMSApiComm.deleteData<IMASResponseDto<boolean>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/email-domains/${id}`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}
