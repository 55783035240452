import { FMSApiComm, FMSReservationApiComm } from 'comm';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto } from 'dto/IMASResponseDto';
import type { UserAccessTokenDto } from 'dto/UserDtos';

/** 실시간 운행률 (점수 및 증감률) **/
export function getRealtimeRate(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/bizcar/dashboard/real-time-operation-rate?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 당일, 7일전 시간별 운행률 조회  **/
export function getOperationRate(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/bizcar/dashboard/today-week-ago-time-operation-rate?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 시간별 예약 건수 **/
export function getTodayReservationTimeCount(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/today-time-operation-car-count?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 운전 점수 현황 - 평균 점수 호출 **/
export function getAverageDrivingScore(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/average-driving-score?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 운전 점수 현황 - 유져/부서별 랭킹 조회 **/
export function getBestDrivingUserRank(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/best-driving-user-rank?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 추정 유류비 & 연비 평균 조회 (유종별 가격) **/
export function getPriceOfFuel(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/bizcar/dashboard/price-of-fuel?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 추정 연비 평균 조회 (점수 및 증감률) **/
export function getAvgFuelEfficiency(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/bizcar/dashboard/avg-fuel-efficiency?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 추정 연비 평균 / 추정 유류비 - 총 주행거리 조회 (라인 그래프, 추정 유류비/연비) **/
export function getTotalMileage(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/bizcar/dashboard/total-mileage?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 추정 연비 평균 조회 - 전체 차량 대수 (막대 그래프) **/
export function getAvgFuelEfficiencyRate(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/bizcar/dashboard/avg-fuel-efficiency-rate?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 전차종 실연비가 높은 모델 **/
export function getBestFuelEfficiencyCarRank(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/bizcar/dashboard/best-Fuel-Efficiency-car-rank?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 추정 유류비 조회 - 추정 유류비 (라인 그래프)**/
export function getEstimatedFuelCost(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/bizcar/dashboard/estimated-fuel-cost?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 조치필요 차량 - 각 항목별 차량 카운트 */
export function getActionRequiredCarsCount(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/requiring-action-car-count` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/** 조치필요 차량 - 응답없음 */
export function getBadConnection(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/bad-connection-cars` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/** 조치필요 차량 - 배터리 방전 */
export function getBatteryWarning(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/battery-warning-cars` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/** 조치필요 차량 - 연료 부족 */
export function getFuelShortage(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/fuel-shortage-cars` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/** 조치필요 차량 - 업무시간 외 운행  */
export function getOffHoursOperation(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/off-hours-operation-cars` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 업무 시간 설정값 불러오기 */
export function getWorkingHours(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/office-hours/manager/bizcar` + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/** 업무 시간 설정 저장 */
export function setWorkingHours(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.patchData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/office-hours/manager/bizcar`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 기존 대시보드 API - 미사용, 확인 후 삭제 예정

/**자산 효율성 평가 */
export function getAssetEfficiency(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/asset-efficiency-assessment?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/**정비 횟수 많은 차량 */
export function getManyRateMaintenance(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/maintenance-rate-ranking?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/**예약율 낮은 차량 */
export function getLowRateCars(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/reservation-rate-ranking?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/**차량 회전율 */
export function getReservationRate(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/monthly-efficiency-assessment?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**주행 및 연료 소모량 */
export function getFuelConsumption(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/monthlyMileageAndFuelConsumption?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**위험성 평가 */
export function getDangerousScore(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/dangerousDrivingRatio?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/**위험운행 top5 */
export function getRiskAssessment(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/bestDangerousDriving?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/**비용 절감성 평가 */
export function getCostSaved(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/monthlySavingAmount?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
/**연비주행 top5 */
export function getDrivingRank(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/dashboard/manager/bizcar/bestFuelEconomyDriving?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
