import Axios from 'axios';
import * as Conf from 'conf';

import { HttpComm } from './HttpComm';

import type { AxiosInstance } from 'axios';

const instance: AxiosInstance = Axios.create({
  baseURL: Conf.FMSApiUrl,
  timeout: 30000,
});

export const FMSApiComm = new HttpComm(instance);
