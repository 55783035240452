import { getPartnerInfo } from 'api/Common';
import { useContext, useState } from 'react';
import Context from 'store/Context';
import {
  PartnerInfoContext,
  PartnerInfoDispatchContext,
} from 'store/PartnerInfoContext';

export default function useThemeWithPartnerInfo() {
  const { userInfo, isLogin } = useContext<any>(Context);
  const partnerInfo = useContext(PartnerInfoContext);
  const setPartnerInfo = useContext(PartnerInfoDispatchContext);

  const [isPartnerInfoLoading, setIsPartnerInfoLoading] = useState(false);

  const defaultTheme = {
    colors: {
      PRIMARY: isLogin ? '#00c3bc' : '#444acf',
    },
    images: {
      MAIN_LOGO: '/resources/common/logo/logoGNB.png',
      LOGIN_LOGO: '/resources/login/main_logo.png',
    },
  };

  const initPartnerInfo = async () => {
    if (!setPartnerInfo || isLogin) return;

    setIsPartnerInfoLoading(true);

    try {
      const response = await getPartnerInfo();
      setPartnerInfo({
        type: 'UPDATE',
        info: response.response.partnerInfo,
      });
    } catch (error) {
      console.error('실패');
    } finally {
      setIsPartnerInfoLoading(false);
    }
  };

  const _partnerInfo = !isLogin ? partnerInfo : userInfo?.partnerInfo;

  const mainLogo =
    _partnerInfo?.images.MAIN_LOGO ?? defaultTheme.images.MAIN_LOGO;

  const loginLogo =
    _partnerInfo?.images.LOGIN_LOGO ?? defaultTheme.images.LOGIN_LOGO;

  const loginBackgroundColor = _partnerInfo?.colors ? '#222222' : '#37384D';

  const primaryColor =
    _partnerInfo?.colors.PRIMARY ?? defaultTheme.colors.PRIMARY;

  return {
    mainLogo,
    loginLogo,
    loginBackgroundColor,
    primaryColor,
    isPartnerInfoLoading,
    initPartnerInfo,
  };
}
