import { FMSApiComm, FMSReservationApiComm } from 'comm';
import { PLATFORM } from 'conf';

import type { IMASResponseDto } from 'dto/IMASResponseDto';
import type { UserAccessTokenDto } from 'dto/UserDtos';
import type {
  PatchPhoneNumberParams,
  PatchPhoneNumberResponse,
} from 'types/api/common';

const BIZ_DOMAIN = 'bizcar';

export const getAccessToken = () => {
  return localStorage.getItem('accessToken') || '';
};
export const getRefreshToken = () => {
  return localStorage.getItem('refreshToken') || '';
};
export const encodeQueryData = (data: any) => {
  const ret: any[] = [];
  for (const d in data)
    ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
};
export interface IMASHeader {
  'Content-Type'?: string;
  Authorization?: string;
  'X-Authorization'?: string;
}

export function genIMASHeader(
  authToken?: string,
  refreshToken?: string,
  contentType?: string,
): IMASHeader {
  let header: IMASHeader = {};
  if (authToken) {
    header = Object.assign(header, { Authorization: 'Bearer ' + authToken });
  }
  if (refreshToken) {
    header = Object.assign(header, {
      'X-Authorization': 'Bearer ' + refreshToken,
    });
  }
  if (contentType) {
    header = Object.assign(header, { 'Content-Type': contentType });
  }
  //alert('genIMASHeader :' + header["Content-Type"]);
  header = Object.assign(header, { AccessAgent: PLATFORM });
  return header;
}
export function verifyToken(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/verify-token?' + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function userInfo(): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/me',
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 지점 호출
export function getBranchList(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/branch?' + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function getTopMenuCount(): Promise<
  IMASResponseDto<UserAccessTokenDto>
> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/reservation/dashboard/manager/bizcar/top',
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

//NOTE Deprecated된 api
export function putPhoneNumber(
  email: string,
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.putData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/user/${email}`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function onChangePassword(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/user/change-password`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 토큰 갱신
export function refreshToken(): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/user/refresh-token',
    genIMASHeader(getRefreshToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function patchPhoneNumber({ userId, body }: PatchPhoneNumberParams) {
  return FMSApiComm.patchData<IMASResponseDto<PatchPhoneNumberResponse>>(
    `/fms/v1/users/${BIZ_DOMAIN}/${userId}/change-phone`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  );
}

export async function getPartnerInfo() {
  const response = await FMSApiComm.getData<IMASResponseDto<any>>(
    `/fms/v1/user/partner?${encodeQueryData({ domain: window.location.hostname })}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  );

  return response.data;
}
