import { useQuery } from '@tanstack/react-query';
import { Car } from 'api';
import { useContext } from 'react';
import Context from 'store/Context';

import { queryClient } from './query-config';
import { QUERY_KEYS } from './query-keys';

import type { UndefinedInitialDataOptions } from '@tanstack/react-query';
import type { IMASSuccessDto, PageDto } from 'dto';
import type {
  GetCarListRequestType,
  GetCarListResponseType,
} from 'types/CarPage';

interface UseGetCarListParams {
  param: GetCarListRequestType;
  options?: Omit<
    UndefinedInitialDataOptions<
      IMASSuccessDto<GetCarListResponseType[], PageDto>
    >,
    'queryKey' | 'queryFn'
  >;
}

export function useGetCarList({ param, options }: UseGetCarListParams) {
  const context: any = useContext(Context);
  const { setNotification } = context;

  const FALL_BACK: {
    response: GetCarListResponseType[];
    page: PageDto;
  } = {
    response: [],
    page: {
      total: 0,
      current: 0,
      last: 0,
    },
  };

  const {
    data = FALL_BACK,
    isLoading,
    refetch,
  } = useQuery({
    ...options,
    queryKey: QUERY_KEYS.getCarList(param),
    queryFn: () => Car.getCarList(param),
    throwOnError: (error) => {
      setNotification({
        type: 'error',
        title: error.message,
        contents: '차량 리스트 조회 실패',
      });
      return true;
    },
  });

  return {
    carList: data.response ?? FALL_BACK.response,
    carListPage: data.page ?? FALL_BACK.page,
    isCarListLoading: isLoading,
    refetchGetCarList: refetch,
  };
}

export function prefetchGetCarList() {
  const INITIAL_PARAM = {
    page: 0,
    size: 10,
  };

  queryClient.prefetchQuery({
    queryKey: QUERY_KEYS.getCarList(INITIAL_PARAM),
    queryFn: () => Car.getCarList(INITIAL_PARAM),
  });

  return null;
}
