import { Common } from 'api';
import _ from 'lodash';

import type { AxiosInstance, AxiosResponse } from 'axios';

class HttpCommImpl {
  private instance: AxiosInstance;
  constructor(axioInst: AxiosInstance) {
    this.instance = axioInst;
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async ({ response }) => {
        const { config, status, data } = response;
        if (!_.includes(data.path, 'refresh-token')) {
          // 토큰이 유효하지 않은 경우
          if (
            _.includes(data.message, 'INVALID_JWT_TOKEN') ||
            _.includes(data.message, 'MODIFIED_TOKEN')
          ) {
            window.alert(
              '사용자 정보가 잘못 되었습니다. 다시 로그인 해 주세요.',
            );
            localStorage.clear();
            window.location.reload();
          }

          // 토큰이 만료된 경우
          if (_.includes(data.message, 'EXPIRED_TOKEN')) {
            await Common.refreshToken()
              .then((res) => {
                localStorage.setItem('accessToken', res.response.accessToken);
                localStorage.setItem('refreshToken', res.response.refreshToken);

                response.config.headers = Object.assign(
                  response.config.headers,
                  {
                    Authorization: 'Bearer ' + res.response.accessToken,
                    'X-Authorization': res.response.refreshToken,
                  },
                );
                this.instance.request(response.config);
              })
              .catch((err) => {
                window.alert(
                  '사용자 정보가 만료 되었습니다. 다시 로그인 해 주세요.',
                );
                localStorage.clear();
                window.location.reload();
              });
          }
        }

        return Promise.reject(data);
      },
    );
  }

  getData<M>(url: string, headers = {}): Promise<AxiosResponse<M>> {
    return this.instance.get(url, { headers: headers });
  }

  postData<M>(url: string, data = {}, headers = {}): Promise<AxiosResponse<M>> {
    return this.instance.post(url, data, { headers: headers });
  }

  postFormData<M>(
    url: string,
    params = {},
    headers = {},
  ): Promise<AxiosResponse<M>> {
    const form = new FormData();
    for (const key in params) {
      const val = Object(params)[key];
      if (Array.isArray(val)) {
        val.map((ele) => {
          form.append(key, ele);
        });
      } else {
        form.append(key, val);
        //form.append(key, Object(params)[key]);
      }
      // form.append(key, Object(params)[key]);
    }

    return this.instance.post(url, form, { headers: headers });
  }

  putData<M>(url: string, data = {}, headers = {}): Promise<AxiosResponse<M>> {
    return this.instance.put(url, data, { headers: headers });
  }

  patchData<M>(
    url: string,
    data = {},
    headers = {},
  ): Promise<AxiosResponse<M>> {
    return this.instance.patch(url, data, { headers: headers });
  }

  putFormData<M>(
    url: string,
    params = {},
    headers = {},
  ): Promise<AxiosResponse<M>> {
    const form = new FormData();
    const obj = Object(params);
    for (const key in params) {
      form.append(key, obj[key]);
    }
    return this.instance.put(url, form, { headers: headers });
  }

  deleteData<M>(
    url: string,
    data = {},
    headers = {},
  ): Promise<AxiosResponse<M>> {
    return this.instance.delete(url, { data: data, headers: headers });
  }
}

export const HttpComm = HttpCommImpl;
