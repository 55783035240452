import { createContext } from 'react';

import type { Dispatch } from 'react';

// state
export interface PartnerInfoType {
  partnerId: number;
  contractDate: string;
  term: number;
  domain: string;
  platform: string;
  colors: {
    PRIMARY: string;
  };
  images: {
    MAIN_LOGO: string;
    LOGIN_LOGO: string;
  };
}

const PartnerInfoContext = createContext<PartnerInfoType | null>(null);

// dispatch
interface Action {
  type: 'UPDATE';
  info: PartnerInfoType;
}

type PartnerInfoDispatchType = Dispatch<Action>;

const PartnerInfoDispatchContext =
  createContext<PartnerInfoDispatchType | null>(null);

function partnerInfoReducer(state: PartnerInfoType, action: Action) {
  switch (action.type) {
    case 'UPDATE':
      return action.info;
    default:
      throw new Error('사용할 수 없는 action 입니다.');
  }
}

export { PartnerInfoContext, PartnerInfoDispatchContext, partnerInfoReducer };
