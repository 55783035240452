import { FMSApiComm } from 'comm';
import { isApiOk } from 'utils/api';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto, IMASResponseType } from 'dto/IMASResponseDto';
import type { UserAccessTokenDto } from 'dto/UserDtos';
import type {
  CancelMaintenanceRequestType,
  GetCarListRequestType,
  GetCarListResponseType,
  GetCarStatusHistoryListRequestType,
  GetCarStatusHistoryListResonseType,
  GetMaintenanceHistoryResponseType,
  GetMaintenanceInfoResponseType,
  GetMaintenancePriceResponseType,
  PatchCarDeptRequestType,
  PostMaintenanceRequestType,
  PutMaintenanceRequestType,
} from 'types/CarPage';

// NOTE useQuery로 전환 완료
export async function getCarList(params: GetCarListRequestType) {
  try {
    const response = await FMSApiComm.getData<
      IMASResponseType<GetCarListResponseType[]>
    >(
      '/fms/v1/car/manager/bizcar?' + encodeQueryData(params),
      genIMASHeader(getAccessToken(), getRefreshToken()),
    ).then((resp) => resp.data);

    if (!isApiOk(response)) {
      throw new Error('[Response Error]: ok is false');
    }

    return response;
  } catch (error) {
    throw new Error(`[Api Error]: ${error}`);
  }
}

// 정비 상태 변경
export async function changeCarStatus(params: any) {
  return FMSApiComm.patchData<IMASResponseType<boolean, null>>(
    `/fms/v1/car/manager/bizcar/maintenance-status`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// NOTE useQuery로 전환 완료
export async function getCarStatusHistoryList(
  params: GetCarStatusHistoryListRequestType,
) {
  try {
    const response = await FMSApiComm.getData<
      IMASResponseType<GetCarStatusHistoryListResonseType>
    >(
      `/fms/v1/car/manager/bizcar/maintenance-status-history/${params.carId}?` +
        encodeQueryData(params),
      genIMASHeader(getAccessToken(), getRefreshToken()),
    ).then((response) => response.data);

    if (!isApiOk(response)) {
      throw new Error(`[Response Error]: ok is false`);
    }
    return response;
  } catch (error) {
    throw new Error(`[Api Error]: ${error}`);
  }
}
export function patchCarStatusHistory(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.patchData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/manager/bizcar/maintenance-status-history-memo`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function deleteCarStatusHistory(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.deleteData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/manager/bizcar/maintenance-status-history/${params.historyId}`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**
 * @function postMaintenance
 * @description 정비 예약 신청
 * @param {PostMaintenanceRequestType} params
 * @returns
 */
export async function postMaintenance(
  params: PostMaintenanceRequestType,
): Promise<IMASResponseDto<any>> {
  return FMSApiComm.postData<IMASResponseDto<any>>(
    `/fms/v1/car/manager/bizcar/maintenance-apply-management`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**
 * @function cancelMaintenance
 * @description 정비 예약 취소
 * @param {CancelMaintenanceRequestType} params
 * @returns
 */
export async function cancelMaintenance(
  params: CancelMaintenanceRequestType,
): Promise<IMASResponseDto<boolean>> {
  return FMSApiComm.postData<IMASResponseDto<boolean>>(
    `/fms/v1/car/manager/bizcar/maintenance-apply-management/cancel`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**
 * @function changeMaintenance
 * @description 정비 예약 변경
 * @param {PutMaintenanceRequestType} params
 * @returns
 */
export async function changeMaintenance(
  params: PutMaintenanceRequestType,
): Promise<IMASResponseDto<any>> {
  return FMSApiComm.putData<IMASResponseDto<any>>(
    `/fms/v1/car/manager/bizcar/maintenance-apply-management`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**
 * @function getMaintenanceInfo
 * @description 정비 신청 상세 정보 조회
 * @param {number} carId
 * @returns {GetMaintenanceInfoResponseType} Promise<IMASResponseDto<getMaintenanceInfoResponseType>>
 */
export async function getMaintenanceInfo(
  carId: number,
): Promise<IMASResponseDto<GetMaintenanceInfoResponseType>> {
  return FMSApiComm.getData<IMASResponseDto<GetMaintenanceInfoResponseType>>(
    `/fms/v1/car/manager/bizcar/maintenance-apply-managements/${carId}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**
 * @function getMaintenanceHistory
 * @description 정비 이력 조회
 * @param {number} carId
 * @returns {GetMaintenanceHistoryResponseType} response
 */
export async function getMaintenanceHistory(
  carId: number,
  page: number,
): Promise<IMASResponseDto<GetMaintenanceHistoryResponseType[]>> {
  return FMSApiComm.getData<
    IMASResponseDto<GetMaintenanceHistoryResponseType[]>
  >(
    `/fms/v1/car/manager/bizcar/maintenance-apply-managements?${encodeQueryData(
      {
        carId: carId,
        page: page,
        size: 3,
      },
    )}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**
 * @function getMaintenancePrice
 * @description 정비 단가표 조회
 * @param {string} modelCode
 * @returns {GetMaintenancePriceResponseType} response
 */
export async function getMaintenancePrice(modelCode: string) {
  return FMSApiComm.getData<
    IMASResponseType<GetMaintenancePriceResponseType[]>
  >(
    `/fms/v1/car/manager/bizcar/maintenance-item-price/${modelCode}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**
 * @function getOnsiteServiceFee
 * @description 정비 서비스 출장비 조회
 * @returns {number} 출장비
 */
export async function getOnsiteServiceFee() {
  return FMSApiComm.getData<IMASResponseDto<number>>(
    `/fms/v1/car/manager/bizcar/maintenance-apply-managements/onsite-service/fee`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

/**
 * @function patchCarDept
 * @description 차량 부서 변경
 * @param {PatchCarDeptRequestType} request
 */
export async function patchCarDept(request: PatchCarDeptRequestType) {
  return FMSApiComm.patchData<IMASResponseType<null, null>>(
    `fms/v1/car/manager/bizcar/dept`,
    request,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
