import Checker from 'container/Checker';
import { prefetchGetCarList } from 'queries';
import {
  Navigate,
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

const LAZY_IMPORTS = {
  layout: () => import('../container/Layout'),
  loginPage: () => import('../pages/LoginPage/LoginPage'),
  reservationPage: () => import('../pages/ReservationPage/ReservationPage'),
  checkReservationPage: () =>
    import('../pages/CheckReservationPage/CheckReservationPage'),
  memberPage: () => import('../pages/MemberPage/MemberPage'),
  carPage: () => import('../pages/CarPage/CarPage'),
  reportPage: () => import('../pages/ReportPage/ReportPage'),
  historyPage: () => import('../pages/HistoryPage/HistoryPage'),
  locationPage: () => import('../pages/LocationPage/LocationPage'),
  finePage: () => import('../pages/FinePage/FinePage'),
  fineIdPage: () => import('../pages/FineIdPage/FineIdPage'),
  logPage: () => import('../pages/LogPage/LogPage'),
};

const ROUTER = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<Checker />}>
      <Route
        path='/'
        lazy={LAZY_IMPORTS.loginPage}
      />
      <Route lazy={LAZY_IMPORTS.layout}>
        <Route path='/reservation/'>
          <Route
            path='management/'
            lazy={LAZY_IMPORTS.reservationPage}
          />
          <Route
            path='check/'
            lazy={LAZY_IMPORTS.checkReservationPage}
          />
          <Route
            index
            element={
              <Navigate
                to='/'
                replace
              />
            }
          />
        </Route>
        <Route
          path='/member'
          lazy={LAZY_IMPORTS.memberPage}
        />
        <Route
          path='/car'
          loader={prefetchGetCarList}
          lazy={LAZY_IMPORTS.carPage}
        />
        <Route
          path='/report'
          lazy={LAZY_IMPORTS.reportPage}
        />
        <Route
          path='/history'
          lazy={LAZY_IMPORTS.historyPage}
        />
        <Route
          path='/location'
          lazy={LAZY_IMPORTS.locationPage}
        />
        <Route path='/fine/'>
          <Route
            index
            lazy={LAZY_IMPORTS.finePage}
          />
          <Route
            path=':fineId/'
            lazy={LAZY_IMPORTS.fineIdPage}
          />
        </Route>
        <Route
          path='/log'
          lazy={LAZY_IMPORTS.logPage}
        />
      </Route>
      <Route
        path='*'
        element={
          <Navigate
            to='/'
            replace
          />
        }
      />
    </Route>,
  ),
);

export default ROUTER;
