import { notification } from 'antd';
import { Common, Log } from 'api';
import { CommonIcons } from 'assets';
import AccessLoading from 'components/AccessLoading';
import useThemeWithPartnerInfo from 'hooks/useThemeWithPartnerInfo';
import { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Context from 'store/Context';
import useInterval from 'use-interval';

const Checker = () => {
  const context: any = useContext(Context);
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [isLoading, setIsLoading] = useState(true);

  const { isPartnerInfoLoading, initPartnerInfo } = useThemeWithPartnerInfo();

  const {
    setIsLogin,
    setNotification,
    notificationData,
    setUserInfo,
    clearData,
    userInfo,
    setIsDownloading,
    downloadExcel,
    triggerDownloadExcel,
    setNoData,
  } = context;

  useEffect(() => {
    checkLogin();
    initPartnerInfo();
  }, []);

  useEffect(() => {
    if (
      !localStorage.getItem('accessToken') &&
      !localStorage.getItem('refreshToken')
    ) {
      isLogout();
    }
  }, [window.location.pathname]);

  useInterval(() => {
    checkToken();
  }, 60000);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userInfo.companyId) {
          api.info({
            message: '운행일지 전체 차량',
            description: (
              <div>
                {downloadExcel.branchName} 전체차량의 운행일지를 다운로드
                중입니다. ({downloadExcel.date[0]} ~ {downloadExcel.date[1]})
                <CommonIcons.Spinner
                  width={20}
                  height={20}
                  style={{ float: 'right' }}
                />
              </div>
            ),
            duration: 0,
            closeIcon: null,
          });
          const params: any = {
            companyId: userInfo.companyId,
            searchStartDate: downloadExcel.date[0],
            searchEndDate: downloadExcel.date[1],
          };
          if (downloadExcel.branchId) {
            params.branchId = downloadExcel.branchId;
          }
          const res = await Log.downloadExcelAll(params);
          if (res.ok) {
            // 새 창 띄우지 않고 다운로드
            const aElem = document.createElement('a');
            aElem.href = res.response.toString();
            aElem.click();

            api.destroy(); // 위에 로딩 notification 제거용
            api.success({
              message: '운행일지 전체 차량',
              description: '운행일지 다운로드가 완료 되었습니다.',
            });
            setIsDownloading(false);
          } else {
            if (res.code === 71003) {
              // NOT_EXIST_RESERVED_HISTORY
              setNoData(true);
            }
            setIsDownloading(false);
            throw res.response;
          }
        }
      } catch (err) {
        setIsDownloading(false);
        console.log('운행일지 전체 차량 다운로드 실패', err);
        api.destroy(); // 위에 로딩 notification 제거용
        api.error({
          message: '운행일지 전체 차량',
          description: '운행일지 다운로드를 실패하였습니다.',
        });
      }
    };
    if (triggerDownloadExcel) {
      setIsDownloading(true);
      fetchData();
    }
  }, [triggerDownloadExcel]);

  useEffect(() => {
    if (notificationData.type) {
      let selectNotification: any = null;
      switch (notificationData.type) {
        case 'success':
          selectNotification = api.success;
          break;
        case 'info':
          selectNotification = api.info;
          break;
        case 'warning':
          selectNotification = api.warning;
          break;
        case 'error':
          selectNotification = api.error;
          break;
        default:
          selectNotification = null;
          break;
      }
      if (selectNotification) {
        selectNotification({
          message: notificationData.title,
          description: notificationData.contents,
        });
        setNotification({
          type: '',
          title: '',
          contents: '',
        });
      }
    }
  }, [notificationData]);

  //* 자동 로그인 체크  */
  const checkLogin = () => {
    if (
      localStorage.getItem('accessToken') &&
      localStorage.getItem('refreshToken')
    ) {
      setIsLoading(true);
      Common.userInfo()
        .then((res) => {
          if (res.ok) {
            setUserInfo(res.response);
            setIsLoading(false);
            setIsLogin(true);
            // if(!LEFTMENU.find((item:any) => item.url === window.location.pathname)){
            //     navigate('/reservation/management')
            // }
          } else {
            throw res;
          }
        })
        .catch((e: any) => {
          isLogout();
        });
    } else {
      isLogout();
    }
  };
  const isLogout = () => {
    setIsLoading(false);
    setIsLogin(false);
    clearData();
    navigate('/', { replace: true });
  };

  //* 토큰 유효성 체크 */
  const checkToken = () => {
    if (
      localStorage.getItem('accessToken') &&
      localStorage.getItem('refreshToken')
    ) {
      Common.verifyToken()
        .then((res) => {
          if (res.ok) {
            const answer: any = res.response;
            if (
              !answer.isAllowed ||
              !answer.isCustomerMatched ||
              !answer.isBranchMatched ||
              !answer.isEmailMatched ||
              !answer.isPasswordMatched
            ) {
              setNotification({
                type: 'error',
                title: '다음 사유로 인해 로그아웃 처리됩니다.',
                contents: (
                  <>
                    {!answer.isAllowed && <div>{`"이용 불가 계정"`}</div>}
                    {!answer.isCustomerMatched && <div>{`"업체 변경"`}</div>}
                    {!answer.isBranchMatched && <div>{`"지점 변경"`}</div>}
                    {!answer.isEmailMatched && <div>{`"이메일 변경"`}</div>}
                    {!answer.isPasswordMatched && (
                      <div>
                        {`"비밀번호 변경 - 변경 일시: ${answer.passwordChangedDate}
                        "`}
                      </div>
                    )}
                    <div>다시 로그인 해 주세요.</div>
                  </>
                ),
              });
              clearData();
              navigate('/login');
            }
          } else {
            throw res.response;
          }
        })
        .catch((err) => {
          console.log('중요 정보 갱신여부 확인 실패:', err);
        });
    }
  };

  return (
    <>
      {contextHolder}
      {isLoading || isPartnerInfoLoading ? <AccessLoading /> : <Outlet />}
    </>
  );
};

export default Checker;
