const PROFILE = process.env.REACT_APP_NODE_ENV;

// v1은 기존 api 재사용, v2는 신규 api
let ApiUrlVal = 'http://127.0.0.1:8888';
let AppApiUrlVal = 'http://127.0.0.1:8880';
let StaffApiUrlVal = 'http://127.0.0.1:8880';
let ReservationApiUrlVal = 'http://127.0.0.1:8880';
let EnvVal = 'test';

if (PROFILE === 'prod') {
  ApiUrlVal = 'https://fms-api.carbom.co.kr';
  AppApiUrlVal = 'https://fms-api-app.carbom.co.kr';
  StaffApiUrlVal = 'https://fms-api-staff.carbom.co.kr';
  ReservationApiUrlVal = 'https://fms-api-reservation.imsconnect.co.kr';
  EnvVal = 'prod';
} else if (PROFILE === 'dev') {
  ApiUrlVal = 'https://fms-dev-api.carbom.co.kr';
  AppApiUrlVal = 'https://fms-dev-api-app.carbom.co.kr';
  StaffApiUrlVal = 'https://fms-dev-api-staff.carbom.co.kr';
  ReservationApiUrlVal = 'https://fms-dev-api-reservation.imsconnect.co.kr';
  EnvVal = 'dev';
}
// TODO: 테스트 용이므로 커밋시 삭제 필요
else {
  ApiUrlVal = 'https://fms-dev-api.carbom.co.kr';
  AppApiUrlVal = 'https://fms-dev-api-app.carbom.co.kr';
  StaffApiUrlVal = 'https://fms-dev-api-staff.carbom.co.kr';
  ReservationApiUrlVal = 'https://fms-dev-api-reservation.imsconnect.co.kr';
  EnvVal = 'local';
}
export const FMSReservationApiUrl = ReservationApiUrlVal;
export const FMSStaffApiUrl = StaffApiUrlVal;
export const FMSAppApiUrl = AppApiUrlVal;
export const FMSApiUrl = ApiUrlVal;
export const Env = EnvVal;
export const PLATFORM = 'FMS_BIZ_WEB';
