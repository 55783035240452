import { useState, Fragment } from 'react';

import Context from './Context';

import type { NotificationDto } from 'dto/CommonDto';

const ContextProvider = ({ children }: any) => {
  //* 예제 */
  const setTestValue = (data: any) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        testValue: data,
      };
    });
  };
  const clearData = () => {
    setDefaultData((prevState) => {
      localStorage.clear();
      return {
        ...prevState,
        isLogin: false,
        userInfo: {},
      };
    });
  };
  const setIsLogin = (flag: boolean) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        isLogin: flag,
      };
    });
  };
  const setUserInfo = (data: any) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        userInfo: data,
      };
    });
  };
  const setNotification = (data: NotificationDto) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        notificationData: data,
      };
    });
  };
  const setTotalCar = (data: number) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        totalCar: data,
      };
    });
  };
  const setIsDownloading = (flag: boolean) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        isDownloading: flag,
      };
    });
  };
  const setDownLoadExcel = (data: any) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        downloadExcel: data,
      };
    });
  };
  const setTriggerDownloadExcel = (flag: boolean) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        triggerDownloadExcel: flag,
      };
    });
  };
  const setNoData = (flag: boolean) => {
    setDefaultData((prevState) => {
      return {
        ...prevState,
        noData: flag,
      };
    });
  };
  const initialContext = {
    isLogin: false,
    testValue: {},
    userInfo: {},
    notificationData: {},
    totalCar: 0,
    isDownloading: false,
    downloadExcel: {},
    triggerDownloadExcel: false,
    noData: false,
    clearData,
    setTestValue,
    setIsLogin,
    setUserInfo,
    setNotification,
    setTotalCar,
    setIsDownloading,
    setDownLoadExcel,
    setTriggerDownloadExcel,
    setNoData,
  };
  const [defaultData, setDefaultData] = useState(initialContext);

  return (
    <Fragment>
      <Context.Provider value={defaultData}>{children}</Context.Provider>
    </Fragment>
  );
};
export default ContextProvider;
