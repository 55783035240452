import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';

const AccessLoading = () => {
  return (
    <Wrap>
      <Contents>
        {/* 로딩 텍스트나 이미지 등 필요할듯, 이후 요청 */}
        {/* Loading... */}
      </Contents>
    </Wrap>
  );
};

export default AccessLoading;

const Wrap = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;

  text-align: center;
  display: flex;
  justify-content: center;
  position: relative;
  align-items: center;
`;
const Contents = styled.div`
  width: 200px;
  line-height: 50px;
  text-align: center;
`;
