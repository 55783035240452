import { FMSApiComm, FMSReservationApiComm } from 'comm';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto } from 'dto/IMASResponseDto';
import type {
  DeleteFineParams,
  DeleteFineResponse,
  GetCompanyLogoResponse,
  GetFineCountResponse,
  GetFineListParams,
  GetFineListResponseWithPage,
  GetFineNoticeHistoryParams,
  GetFineNoticeHistoryResponseWithPage,
  GetFineParams,
  GetFineResponseWithPage,
  GetFineTypeListResponse,
  GetFinedMemberListParams,
  GetFinedMemberListResponseWithPage,
  GetMemberFineHistoryParams,
  GetMemberFineHistoryResponseWithPage,
  GetMemberSimpleParams,
  GetMemberSimpleResponseWithPage,
  GetValidCarNumberParams,
  GetValidCarNumberResponse,
  PatchBlockUserParams,
  PatchFineNoticeParams,
  PatchFineNoticeResponse,
  PatchFineParams,
  PatchFineResponse,
  PatchUnblockUserParams,
  PostCompanyLogoParams,
  PostCompanyLogoResponse,
  PostFineParams,
  PostFineResponse,
  PostUploadImageParams,
  PostUploadImageResponse,
} from 'types/api/fine';

const BIZ_DOMAIN = 'bizcar';

// 납부자 등록시 회원 검색 모달창에서 회원 목록 조회 (fms-dev-api.carbom.co.kr)
export function getMemberSimple({
  searchType,
  searchText,
  pagination,
}: GetMemberSimpleParams) {
  return FMSApiComm.getData<IMASResponseDto<GetMemberSimpleResponseWithPage>>(
    `/fms/v1/users/manager/${BIZ_DOMAIN}/simple-info-for-fine-regist?${encodeQueryData(
      { searchType, searchText, ...pagination },
    )}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 목록 조회 (fms-dev-api-reservation.imsconnect.co.kr)
export function getFineList({
  searchFineType,
  searchUserType,
  searchTextType,
  searchText,
  isIncludeDetached,
  pagination,
}: GetFineListParams) {
  return FMSReservationApiComm.getData<
    IMASResponseDto<GetFineListResponseWithPage>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}?${encodeQueryData({
      searchFineType: searchFineType ?? '',
      searchUserType,
      searchTextType,
      searchText,
      isIncludeDetached,
      ...pagination,
    })}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 등록하기 (fms-dev-api-reservation.imsconnect.co.kr)
export function postFine({ body }: PostFineParams) {
  return FMSReservationApiComm.postData<IMASResponseDto<PostFineResponse>>(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회사 로고 이미지 조회 (fms-dev-api-reservation.imsconnect.co.kr)
export function getCompanyLogo() {
  return FMSReservationApiComm.getData<IMASResponseDto<GetCompanyLogoResponse>>(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/company-logo`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 회사 로고 이미지 업로드 (fms-dev-api-reservation.imsconnect.co.kr)
export function postCompanyLogo({ body }: PostCompanyLogoParams) {
  return FMSReservationApiComm.postData<
    IMASResponseDto<PostCompanyLogoResponse>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/company-logo`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 건수(납부자지정, 미지정) 조회 (fms-dev-api-reservation.imsconnect.co.kr)
export function getFineCount() {
  return FMSReservationApiComm.getData<IMASResponseDto<GetFineCountResponse>>(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/fine-count`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 안내 메일 발송 이력 조회 (fms-dev-api-reservation.imsconnect.co.kr)
export function getFineNoticeHistory({
  fineId,
  pagination,
}: GetFineNoticeHistoryParams) {
  return FMSReservationApiComm.getData<
    IMASResponseDto<GetFineNoticeHistoryResponseWithPage>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/fine-notice-history/${fineId}?${encodeQueryData(
      { ...pagination },
    )}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 종류 목록 조회 (fms-dev-api-reservation.imsconnect.co.kr)
export function getFineTypeList() {
  return FMSReservationApiComm.getData<
    IMASResponseDto<GetFineTypeListResponse>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/fine-type`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 등록된 회원 목록 조회 (fms-dev-api-reservation.imsconnect.co.kr)
export function getFinedMemberList({
  searchTextType,
  searchText,
  pagination,
}: GetFinedMemberListParams) {
  return FMSReservationApiComm.getData<
    IMASResponseDto<GetFinedMemberListResponseWithPage>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/fined-users?${encodeQueryData(
      { searchTextType, searchText, ...pagination },
    )}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 임시 사진파일 업로드 (fms-dev-api-reservation.imsconnect.co.kr)
export function uploadImage({ body }: PostUploadImageParams) {
  return FMSReservationApiComm.postFormData<
    IMASResponseDto<PostUploadImageResponse>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/image`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 안내 메일 발송 (fms-dev-api-reservation.imsconnect.co.kr)
export function patchFineNotice({ body }: PatchFineNoticeParams) {
  return FMSReservationApiComm.patchData<
    IMASResponseDto<PatchFineNoticeResponse>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/send-fine-notice-mail`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 특정 회원 과태료 조회 (fms-dev-api-reservation.imsconnect.co.kr)
export function getMemberFineHistory({
  userId,
  pagination,
}: GetMemberFineHistoryParams) {
  return FMSReservationApiComm.getData<
    IMASResponseDto<GetMemberFineHistoryResponseWithPage>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/users/${userId}?${encodeQueryData(
      { ...pagination },
    )}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 차량번호 유효성 검증 (fms-dev-api-reservation.imsconnect.co.kr)
export function getValidCarNumber({ carNumber }: GetValidCarNumberParams) {
  return FMSReservationApiComm.getData<
    IMASResponseDto<GetValidCarNumberResponse>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/valid-car-number?${encodeQueryData(
      { carNumber },
    )}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 상세 조회 (fms-dev-api-reservation.imsconnect.co.kr)
export function getFine({ fineId }: GetFineParams) {
  return FMSReservationApiComm.getData<
    IMASResponseDto<GetFineResponseWithPage>
  >(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/${fineId}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 삭제 (fms-dev-api-reservation.imsconnect.co.kr)
export function deleteFine({ fineId }: DeleteFineParams) {
  return FMSReservationApiComm.deleteData<IMASResponseDto<DeleteFineResponse>>(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/${fineId}`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 과태료 수정 (fms-dev-api-reservation.imsconnect.co.kr)
export function patchFine({ fineId, body }: PatchFineParams) {
  return FMSReservationApiComm.patchData<IMASResponseDto<PatchFineResponse>>(
    `/fms/v1/reservation/fine/manager/${BIZ_DOMAIN}/${fineId}`,
    body,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 서비스 제한 (fms-dev-api.carbom.co.kr)
export function patchBlockUser({ userId }: PatchBlockUserParams) {
  return FMSApiComm.patchData<IMASResponseDto<never>>(
    `/fms/v1/users/manager/bizcar/${userId}/block`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}

// 서비스 제한 해지 (fms-dev-api.carbom.co.kr)
export function patchUnblockUser({ userId }: PatchUnblockUserParams) {
  return FMSApiComm.patchData<IMASResponseDto<never>>(
    `/fms/v1/users/manager/bizcar/${userId}/unblock`,
    {},
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((response) => response.data);
}
