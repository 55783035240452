import { FMSApiComm, FMSAppApiComm } from 'comm';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto, IMASResponseType } from 'dto/IMASResponseDto';
import type { UserAccessTokenDto } from 'dto/UserDtos';
import type {
  ByIdsByCustomerRequest,
  ByIdsByCustomerResponse,
  GetByIdsByCustomerRequest,
  GetByIdsByCustomerResponse,
} from 'types/api/location';

export async function byIds(params: ByIdsByCustomerRequest) {
  return FMSApiComm.postData<IMASResponseType<ByIdsByCustomerResponse[]>>(
    '/fms/v1/car/by-ids-by-customer',
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
export async function getByIds(params: GetByIdsByCustomerRequest) {
  return FMSApiComm.getData<IMASResponseType<GetByIdsByCustomerResponse[]>>(
    `/fms/v1/car/by-ids-by-customer?ids=${params.ids[0]}`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function clusteredLocations(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/clustered-location-list-stable-by-customer?' +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function detailAddress(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/gps/address/detail',
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function branchList(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/branch?' + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function carSpecs(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/spec?' + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function ignitionControl(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/${params.id}/ignition-control?lock=${params.flag}`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function reservation(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/${params.id}/ignition-control/reserve`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function cancelReservation(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.putData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/${params.id}/ignition-control/cancel`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function doorControl(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/${params.id}/door-lock/${params.flag}/0/0`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function controlHistory(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSAppApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/app/v2/car/' +
      params.id +
      '/ignition-control-history/renewal?' +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function nowOdometerData(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/${params.id}/odometer`,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function odometerHistory(
  params?: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/${params.id}/updateTotalMileages?` + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

export function updateMileage(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.postData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/car/${params.id}/updateTotalMileage`,
    params,
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
