import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import AntdProvider from 'providers/AntdProvider';
import { queryClient } from 'queries/query-config';
import { RouterProvider } from 'react-router-dom';
import ROUTER from 'routes/Router';
import ContextProvider from 'store/ContextProvider';
import PartnerInfoProvider from 'store/PartnerInfoProvider';
import GlobalStyle from 'styles/GlobalStyle';

function App() {
  return (
    <ContextProvider>
      <PartnerInfoProvider>
        <AntdProvider>
          <GlobalStyle />
          <QueryClientProvider client={queryClient}>
            <RouterProvider router={ROUTER} />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </AntdProvider>
      </PartnerInfoProvider>
    </ContextProvider>
  );
}

export default App;
