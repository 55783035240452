import { useReducer } from 'react';

import {
  PartnerInfoContext,
  PartnerInfoDispatchContext,
  partnerInfoReducer,
} from './PartnerInfoContext';

import type { PartnerInfoType } from './PartnerInfoContext';

const initialPartnerInfo: PartnerInfoType = {
  partnerId: 0,
  contractDate: '',
  term: 0,
  domain: '',
  platform: '',
  colors: {
    PRIMARY: '',
  },
  images: {
    MAIN_LOGO: '',
    LOGIN_LOGO: '',
  },
};

const PartnerInfoProvider = ({ children }: { children: React.ReactNode }) => {
  const [partnerInfo, setPartnerInfo] = useReducer(
    partnerInfoReducer,
    initialPartnerInfo,
  );

  return (
    <PartnerInfoDispatchContext.Provider value={setPartnerInfo}>
      <PartnerInfoContext.Provider value={partnerInfo}>
        {children}
      </PartnerInfoContext.Provider>
    </PartnerInfoDispatchContext.Provider>
  );
};

export default PartnerInfoProvider;
