import { ConfigProvider } from 'antd';
import useThemeWithPartnerInfo from 'hooks/useThemeWithPartnerInfo';

import type { ReactNode } from 'react';

const Provider = ({ children }: { children: ReactNode }) => {
  const { primaryColor } = useThemeWithPartnerInfo();

  return (
    <ConfigProvider
      theme={{
        token: {
          fontFamily: 'Pretendard',
          colorPrimary: primaryColor,
          colorText: 'var(--black-222)',
          borderRadius: 8,
        },
        components: {
          Table: {
            headerBg: 'transparent',
            headerSplitColor: 'transparent',
            headerBorderRadius: 0,
            cellFontSize: 15,
            cellPaddingBlock: 7,
            cellPaddingInline: 20,
          },
        },
      }}
      form={{
        validateMessages: {
          required: '',
          pattern: {
            mismatch: '',
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default Provider;
