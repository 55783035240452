import { FMSApiComm, FMSReservationApiComm } from 'comm';

import {
  encodeQueryData,
  genIMASHeader,
  getAccessToken,
  getRefreshToken,
} from './Common';

import type { IMASResponseDto } from 'dto/IMASResponseDto';
import type { UserAccessTokenDto } from 'dto/UserDtos';

// 브랜치별 차량리스트 호출
export function getCarList(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    '/fms/v1/car/by-customer?' + encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 개별 차량 다운로드
export function downloadExcelbyCarId(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/report/bizcar/rental-report/excel/car?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}

// 전체 차량 다운로드
export function downloadExcelAll(
  params: any,
): Promise<IMASResponseDto<UserAccessTokenDto>> {
  return FMSReservationApiComm.getData<IMASResponseDto<UserAccessTokenDto>>(
    `/fms/v1/reservation/report/bizcar/rental-report/excel?` +
      encodeQueryData(params),
    genIMASHeader(getAccessToken(), getRefreshToken()),
  ).then((resp) => resp.data);
}
