import type {
  GetCarListRequestType,
  GetCarStatusHistoryListRequestType,
} from 'types/CarPage';

export const QUERY_KEYS = {
  getCarList: (param: GetCarListRequestType) => ['carList', param],
  carStatusHistoryList: (param: GetCarStatusHistoryListRequestType) => [
    'carStatusHistoryList',
    param,
  ],
};
