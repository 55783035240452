import Axios from 'axios';
import * as Conf from 'conf';

import { HttpComm } from './HttpComm';

import type { AxiosInstance } from 'axios';

const instance: AxiosInstance = Axios.create({
  baseURL: Conf.FMSStaffApiUrl,
  timeout: 60000,
});

export const FMSStaffApiComm = new HttpComm(instance);
